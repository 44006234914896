import React from 'react';
import classNames from 'classnames';
import IconDesignSystemLogo from '../components/icons/IconDesignSystemLogo';
import AthleteMale from '../imgs/athlete-1.png';
import BackgroundMale from '../imgs/background-1.jpg';
import AthleteFemale from '../imgs/athlete-3.png';
import BackgroundFemale from '../imgs/background-3.jpg';
import Hatching from '../imgs/hatching.png';
import Loadable from 'react-loadable';
import styles from './index.module.scss';
import '@hudl/uniform-ui-components/lib/style.css';

const showFemale = Math.floor(Math.random() * 2 + 1) === 2;

const PageWithSubNav = Loadable({
  loader: () => import('../components/page-with-subnav/PageWithSubNav'),
  loading: () => null,
});

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bgLoaded: false,
      athleteLoaded: false,
    };
  }

  startMouseOver() {
    if (typeof window !== 'undefined') {
      const contentWrapperEl = document.getElementById('contentWrapper');
      const contentWrapperStyleEl = document.getElementById(
        'contentWrapperStyle'
      );
      const backgroundImgEl = document.getElementById('backgroundImg');
      const athleteImgEl = document.getElementById('athleteImg');
      contentWrapperEl.addEventListener('mousemove', e => {
        const osX = e.offsetX;
        const osY = e.offsetY;
        const height = contentWrapperEl.clientHeight;
        const width = contentWrapperEl.clientWidth;
        const lp = Math.abs(Math.floor((100 / width) * osX) - 100);
        const tp = Math.abs(Math.floor((100 / height) * osY) - 100);
        const backgroundStyle = `background-position: ${lp}% ${tp}%;`;
        contentWrapperStyleEl.innerHTML = `#hatchingContainer::after { ${backgroundStyle} }`;
        backgroundImgEl.style.transform = `translateX(${osX / 128}px)`;
        athleteImgEl.style.transform = `translateX(-${osX / 256}px)`;
      });
    }
  }

  render() {
    const hatchingClasses = classNames(styles.hatchingContainer, {
      [styles.isFemaleHatching]: showFemale,
    });
    return (
      <PageWithSubNav collapsePadding>
        <div id="contentWrapper" className={styles.mainContentWrapper}>
          <div className={styles.layoutWrapper}>
            <div className={styles.backgroundContainer}>
              <img
                id="backgroundImg"
                src={showFemale ? BackgroundFemale : BackgroundMale}
                onLoad={() => {
                  this.startMouseOver();
                  this.setState({ bgLoaded: true });
                }}
              />
            </div>
            <div className={styles.athleteContainer}>
              <img
                id="athleteImg"
                src={showFemale ? AthleteFemale : AthleteMale}
                onLoad={() => {
                  this.setState({ athleteLoaded: true });
                }}
              />
            </div>
            <div id="hatchingContainer" className={hatchingClasses}>
              <img src={Hatching} />
            </div>
            {this.state.bgLoaded &&
              this.state.athleteLoaded && (
              <div className={styles.heroWrapper}>
                <IconDesignSystemLogo />
              </div>
            )}
            <svg
              className={styles.bottomAngle}
              width="126"
              height="196"
              viewBox="0 0 126 196"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 196L72.5696 67.2H86.1266L126 0V196H32.75L81.5 109H75L26 196H0Z"
                fill="#101417"
              />
            </svg>
          </div>
        </div>
        <style id="contentWrapperStyle" />
      </PageWithSubNav>
    );
  }
}
