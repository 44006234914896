import React from 'react';

const IconDesignSystemLogo = ({ className }) => {
  return (
    <svg
      width="945"
      height="346"
      viewBox="0 0 945 346"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <defs>
        <filter id="letterU">
          <feGaussianBlur
            id="letterUBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
        <filter id="letterN">
          <feGaussianBlur
            id="letterNBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
        <filter id="letterI">
          <feGaussianBlur
            id="letterIBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
        <filter id="letterF">
          <feGaussianBlur
            id="letterFBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
        <filter id="letterO">
          <feGaussianBlur
            id="letterOBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
        <filter id="letterR">
          <feGaussianBlur
            id="letterRBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
        <filter id="letterM">
          <feGaussianBlur
            id="letterMBlur"
            in="SourceGraphic"
            stdDeviation="7"
          />
        </filter>
      </defs>
      <animate
        xlinkHref="#letterUBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="600ms"
        repeatCount="1"
        fill="freeze"
      />
      <animate
        xlinkHref="#letterNBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="400ms"
        repeatCount="1"
        fill="freeze"
      />
      <animate
        xlinkHref="#letterIBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="200ms"
        repeatCount="1"
        fill="freeze"
      />
      <animate
        xlinkHref="#letterFBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="800ms"
        repeatCount="1"
        fill="freeze"
      />
      <animate
        xlinkHref="#letterOBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="300ms"
        repeatCount="1"
        fill="freeze"
      />
      <animate
        xlinkHref="#letterRBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="500ms"
        repeatCount="1"
        fill="freeze"
      />
      <animate
        xlinkHref="#letterMBlur"
        attributeName="stdDeviation"
        from="7"
        to="0"
        dur="300ms"
        begin="700ms"
        repeatCount="1"
        fill="freeze"
      />
      <g id="designSystemFooter">
        <path
          d="M687.973 252.285H690.661V276.509H687.973V265.565H682.752V276.509H680.063V252.285H682.752V263.267H687.973V252.285Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M701.331 257.62H704.019V276.509H701.331V275.224C701.045 276.08 700.331 276.509 699.188 276.509H697.629C695.577 276.509 694.551 275.483 694.551 273.432V257.62H697.24V273.004C697.24 273.523 697.318 273.861 697.473 274.016C697.629 274.146 697.954 274.211 698.448 274.211H700.006C700.474 274.211 700.811 274.107 701.019 273.899C701.227 273.692 701.331 273.328 701.331 272.809V257.62Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M714.111 250.454H716.8V276.509H714.111V275.224C713.826 276.08 713.111 276.509 711.968 276.509H710.41C708.358 276.509 707.332 275.483 707.332 273.432V260.658C707.332 258.607 708.358 257.581 710.41 257.581H711.968C713.111 257.581 713.826 258.01 714.111 258.867V250.454ZM711.228 274.211H712.787C713.254 274.211 713.592 274.107 713.8 273.899C714.007 273.692 714.111 273.328 714.111 272.809V261.281C714.111 260.762 714.007 260.398 713.8 260.191C713.592 259.983 713.254 259.879 712.787 259.879H711.228C710.709 259.879 710.371 259.957 710.215 260.113C710.085 260.243 710.02 260.567 710.02 261.087V273.004C710.02 273.523 710.085 273.861 710.215 274.016C710.371 274.146 710.709 274.211 711.228 274.211Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M723 276.509H720.312V250.454H723V276.509Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M731.964 252.285H739.64C741.692 252.285 742.718 253.31 742.718 255.362V273.432C742.718 275.483 741.692 276.509 739.64 276.509H731.964V252.285ZM740.03 273.004V255.79C740.03 255.271 739.952 254.946 739.796 254.816C739.64 254.661 739.315 254.583 738.822 254.583H734.653V274.211H738.822C739.315 274.211 739.64 274.146 739.796 274.016C739.952 273.861 740.03 273.523 740.03 273.004Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M749.492 257.62H753.077C755.129 257.62 756.155 258.646 756.155 260.697V267.98H749.103V273.004C749.103 273.523 749.181 273.861 749.337 274.016C749.492 274.146 749.817 274.211 750.311 274.211H752.142C752.662 274.211 752.986 274.146 753.116 274.016C753.272 273.861 753.35 273.523 753.35 273.004V271.29H756.038V273.432C756.038 275.483 755.012 276.509 752.96 276.509H749.492C747.44 276.509 746.414 275.483 746.414 273.432V260.697C746.414 258.646 747.44 257.62 749.492 257.62ZM749.103 265.682H753.467V261.125C753.467 260.632 753.389 260.308 753.233 260.152C753.077 259.996 752.752 259.918 752.259 259.918H750.311C749.817 259.918 749.492 259.996 749.337 260.152C749.181 260.308 749.103 260.632 749.103 261.125V265.682Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M765.081 273.043V269.46C765.081 268.966 765.003 268.642 764.847 268.486C764.691 268.33 764.367 268.252 763.873 268.252H761.964C759.912 268.252 758.886 267.227 758.886 265.176V260.697C758.886 258.646 759.912 257.62 761.964 257.62H764.614C766.666 257.62 767.692 258.646 767.692 260.697V262.605H765.042V261.087C765.042 260.567 764.964 260.243 764.808 260.113C764.678 259.957 764.354 259.879 763.834 259.879H762.743C762.224 259.879 761.886 259.957 761.73 260.113C761.6 260.243 761.535 260.567 761.535 261.087V264.786C761.535 265.28 761.6 265.604 761.73 265.76C761.886 265.916 762.224 265.994 762.743 265.994H764.653C766.705 265.994 767.731 267.019 767.731 269.07V273.432C767.731 275.483 766.705 276.509 764.653 276.509H762.042C759.99 276.509 758.964 275.483 758.964 273.432V271.524H761.613V273.043C761.613 273.536 761.678 273.861 761.808 274.016C761.964 274.172 762.302 274.25 762.821 274.25H763.873C764.367 274.25 764.691 274.172 764.847 274.016C765.003 273.861 765.081 273.536 765.081 273.043Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M770.765 253.57V250.416H773.609V253.57H770.765ZM773.531 276.509H770.843V257.62H773.531V276.509Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M783.63 257.62H786.319V278.923C786.319 280.974 785.293 282 783.24 282H777.435V279.702H782.422C782.916 279.702 783.24 279.624 783.396 279.469C783.552 279.313 783.63 278.988 783.63 278.495V275.224C783.344 276.08 782.63 276.509 781.487 276.509H779.929C777.876 276.509 776.85 275.483 776.85 273.432V260.697C776.85 258.646 777.876 257.62 779.929 257.62H781.487C782.63 257.62 783.344 258.049 783.63 258.906V257.62ZM780.747 274.211H782.305C782.773 274.211 783.111 274.107 783.318 273.899C783.526 273.692 783.63 273.328 783.63 272.809V261.32C783.63 260.801 783.526 260.437 783.318 260.23C783.111 260.022 782.773 259.918 782.305 259.918H780.747C780.227 259.918 779.89 259.996 779.734 260.152C779.604 260.308 779.539 260.632 779.539 261.125V273.004C779.539 273.523 779.604 273.861 779.734 274.016C779.89 274.146 780.227 274.211 780.747 274.211Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M794.662 257.62H796.22C798.272 257.62 799.298 258.646 799.298 260.697V276.509H796.61V261.125C796.61 260.632 796.532 260.308 796.376 260.152C796.246 259.996 795.922 259.918 795.402 259.918H793.844C793.376 259.918 793.038 260.022 792.83 260.23C792.623 260.437 792.519 260.801 792.519 261.32V276.509H789.83V257.62H792.519V258.906C792.804 258.049 793.519 257.62 794.662 257.62Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M814.607 273.004V266.383C814.607 265.864 814.529 265.539 814.373 265.409C814.217 265.254 813.893 265.176 813.399 265.176H810.75C808.698 265.176 807.671 264.15 807.671 262.099V255.362C807.671 253.31 808.698 252.285 810.75 252.285H814.178C816.231 252.285 817.257 253.31 817.257 255.362V259.1H814.568V255.79C814.568 255.271 814.49 254.946 814.334 254.816C814.204 254.661 813.88 254.583 813.36 254.583H811.568C811.048 254.583 810.711 254.661 810.555 254.816C810.425 254.946 810.36 255.271 810.36 255.79V261.671C810.36 262.164 810.425 262.489 810.555 262.644C810.711 262.8 811.048 262.878 811.568 262.878H814.217C816.27 262.878 817.296 263.904 817.296 265.955V273.432C817.296 275.483 816.27 276.509 814.217 276.509H810.828C808.775 276.509 807.749 275.483 807.749 273.432V269.693H810.438V273.004C810.438 273.523 810.503 273.861 810.633 274.016C810.789 274.146 811.126 274.211 811.646 274.211H813.399C813.893 274.211 814.217 274.146 814.373 274.016C814.529 273.861 814.607 273.523 814.607 273.004Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M823.029 282H820.925V279.702H822.211C823.328 279.702 823.977 279.3 824.159 278.495L824.627 276.509H822.873L819.25 257.62H821.938L824.977 274.639L827.822 257.62H830.51L826.731 278.923C826.341 280.974 825.107 282 823.029 282Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M838.443 273.043V269.46C838.443 268.966 838.365 268.642 838.209 268.486C838.053 268.33 837.729 268.252 837.235 268.252H835.326C833.274 268.252 832.248 267.227 832.248 265.176V260.697C832.248 258.646 833.274 257.62 835.326 257.62H837.975C840.027 257.62 841.054 258.646 841.054 260.697V262.605H838.404V261.087C838.404 260.567 838.326 260.243 838.17 260.113C838.04 259.957 837.716 259.879 837.196 259.879H836.105C835.586 259.879 835.248 259.957 835.092 260.113C834.962 260.243 834.897 260.567 834.897 261.087V264.786C834.897 265.28 834.962 265.604 835.092 265.76C835.248 265.916 835.586 265.994 836.105 265.994H838.014C840.066 265.994 841.092 267.019 841.092 269.07V273.432C841.092 275.483 840.066 276.509 838.014 276.509H835.404C833.352 276.509 832.326 275.483 832.326 273.432V271.524H834.975V273.043C834.975 273.536 835.04 273.861 835.17 274.016C835.326 274.172 835.664 274.25 836.183 274.25H837.235C837.729 274.25 838.053 274.172 838.209 274.016C838.365 273.861 838.443 273.536 838.443 273.043Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M849.738 259.918H847.049V272.809C847.049 273.302 847.127 273.627 847.283 273.783C847.439 273.938 847.763 274.016 848.257 274.016H849.738V276.509H847.439C845.387 276.509 844.361 275.483 844.361 273.432V259.918H842.646V257.62H844.361V254.544H847.049V257.62H849.738V259.918Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M854.969 257.62H858.554C860.606 257.62 861.632 258.646 861.632 260.697V267.98H854.579V273.004C854.579 273.523 854.657 273.861 854.813 274.016C854.969 274.146 855.294 274.211 855.787 274.211H857.619C858.138 274.211 858.463 274.146 858.593 274.016C858.749 273.861 858.827 273.523 858.827 273.004V271.29H861.515V273.432C861.515 275.483 860.489 276.509 858.437 276.509H854.969C852.917 276.509 851.891 275.483 851.891 273.432V260.697C851.891 258.646 852.917 257.62 854.969 257.62ZM854.579 265.682H858.943V261.125C858.943 260.632 858.866 260.308 858.71 260.152C858.554 259.996 858.229 259.918 857.736 259.918H855.787C855.294 259.918 854.969 259.996 854.813 260.152C854.657 260.308 854.579 260.632 854.579 261.125V265.682Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
        <path
          d="M876.363 257.62H877.922C879.974 257.62 881 258.646 881 260.697V276.509H878.312V261.125C878.312 260.632 878.234 260.308 878.078 260.152C877.948 259.996 877.623 259.918 877.104 259.918H875.545C875.078 259.918 874.74 260.022 874.532 260.23C874.324 260.437 874.22 260.801 874.22 261.32V276.509H871.532V261.125C871.532 260.632 871.454 260.308 871.298 260.152C871.168 259.996 870.843 259.918 870.324 259.918H868.765C868.298 259.918 867.96 260.022 867.752 260.23C867.545 260.437 867.441 260.801 867.441 261.32V276.509H864.752V257.62H867.441V258.906C867.726 258.049 868.441 257.62 869.584 257.62H871.142C872.623 257.62 873.571 258.14 873.987 259.178C874.246 258.14 875.039 257.62 876.363 257.62Z"
          fill="#E6F2FF"
          fillOpacity="0.8"
        />
      </g>
      <path
        id="uniform-u"
        d="M131.769 64H171.487V209.745C171.487 230.288 159.729 240.559 136.213 240.559H98.9956C75.6652 240.559 64 230.288 64 209.745V64H103.717V204.47C103.717 209.097 106.124 211.41 110.939 211.41H124.27C129.27 211.41 131.769 209.097 131.769 204.47V64Z"
        fill="white"
      />
      <path
        id="uniform-n"
        d="M260.985 64H297.37V240.559H261.818L223.212 134.79V240.559H187.106V64H222.934L260.985 169.491V64Z"
        fill="white"
      />
      <path
        id="uniform-i"
        d="M314.021 64H353.461V240.559H314.021V64Z"
        fill="white"
      />
      <path
        id="uniform-f"
        d="M465.366 93.149H410.095V141.731H456.478V169.214H410.095V240.559H370.377V64H465.366V93.149Z"
        fill="white"
      />
      <path
        id="uniform-o"
        d="M515.474 64H552.692C576.022 64 587.687 74.179 587.687 94.537V209.745C587.687 230.288 576.022 240.559 552.692 240.559H515.474C491.959 240.559 480.201 230.288 480.201 209.745V94.537C480.201 74.179 491.959 64 515.474 64ZM547.97 204.47V100.089C547.97 95.4624 545.563 93.149 540.749 93.149H527.417C522.418 93.149 519.918 95.4624 519.918 100.089V204.47C519.918 209.097 522.418 211.41 527.417 211.41H540.749C545.563 211.41 547.97 209.097 547.97 204.47Z"
        fill="white"
      />
      <path
        id="uniform-r"
        d="M644.949 174.488V240.559H605.509V64H678.278C701.609 64 713.274 74.179 713.274 94.537V143.951C713.274 160.238 705.867 170.047 691.054 173.378L722.162 240.559H679.667L651.893 174.488H644.949ZM644.949 93.149V146.45H666.335C668.928 146.45 670.779 145.987 671.89 145.062C673.001 143.951 673.557 142.193 673.557 139.787V100.089C673.557 95.4624 671.149 93.149 666.335 93.149H644.949Z"
        fill="white"
      />
      <path
        id="uniform-m"
        d="M772.695 240.559H735.702V64H779.029L808.192 163.939L837.356 64H880.683V240.559H845.688V122.02L822.913 202.249H793.472L770.697 122.298L772.695 240.559Z"
        fill="white"
      />
    </svg>
  );
};

export default IconDesignSystemLogo;
